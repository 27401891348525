import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import notiicon from "assets/NotificationIcon.png";
import deleteimg from "assets/delete.png";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  headertitle: {
    marginBottom: "0",
    marginLeft: "40px",
    color: "#000",
    fontWeight: "400",
  },
  notificationtitle: {
    marginTop: "0",
    fontSize: "15px",
    marginLeft: "42px",
    color: "#000",
    fontWeight: "400",
  },
  gridcontainer: {
    backgroundColor: "#eeeeee",
    height: "498px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardui: {
    textAlign: "center",
    margin: "none",
  },
  cardheader: {
    fontSize: "70px",
    marginBottom: "0",
    fontWeight: "900",
    color: "#F8A11C",
  },
  cardheadermedile: {
    fontSize: "70px",
    marginBottom: "0",
    fontWeight: "900",
    color: "#000",
  },
  carddesc: {
    marginTop: "0",
    marginBottom: "30px",
  },
  reporttag: {
    backgroundColor: "#eee",
    color: "#000",
    marginBottom: "0",
    boxShadow: "unset",
    width: "200px",
    marginLeft: "75px",
    marginTop: "65px",
    textAlign: "center",
    padding: "10px",
  },

  selectui: {
    position: "relative",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "36px",
    height: "30px",
    bottom: "-65px",
    width: "200px",
    textAlign: "center",
    border: "solid 2px #CDD1D4",
    padding: "20px",
  },
  notiicon: {
    width: "10px",
    marginRight: "5px",
  },
  customers: {
    fontFamily: "Arial, Helvetica, sans-serif",
    borderCollapse: "collapse",
    width: "100%",
  },
  customerstd: {
    border: "1px solid #ddd",
    padding: "8px",
  },
  tableUpgradeWrapper: {
    width: "100%",
    overflowX: "auto",
    backgroundColor: "#eee",
    MsOverflowStyle: "-ms-autohiding-scrollbar",
    WebkitOverflowScrolling: "touch",
    display: "flex",
    flexDirection: "column",
    padding:"2%"
  },
  table: {
    width: "100%",
    display: "table",
    marginTop: "4%",
    overflowX: "auto",
    borderColor: "grey",
    borderSpacing: "2px",
    borderCollapse: "collapse",
    backgroundColor: "white",
    "& thdead tr th": {
      fontSize: "1.063rem",
      padding: "12px 8px",
      verticalAlign: "middle",
      fontWeight: "300",
      borderTopWidth: "0",
      borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
      textAlign: "inherit",
    },
    "& tbody tr td": {
      padding: "12px 8px",
      verticalAlign: "middle",
      borderTop: "1px solid rgba(0, 0, 0, 0.06)",
    },
    "& td, & th": {
      display: "table-cell",
    },
  },
  center: {
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  colortd: {
    backgroundColor: "#CDD1D4",
    textAlign: "center",
    padding: "10px",
    whiteSpace: "nowrap",
  },
  imgwidth: {
    width: "19%",
    marginRight: "5px",
  },
  dropdownimg: {
    width: "37px",
    position: "relative",
    top: "2px",
  },
  textright: {
    textAlign: "right",
  },
  divright: {
    textAlign: "right",
    marginBottom: "50px",
  },
  diplayblock: {
    display: "block",
  },
  displayinlineblock: {
    display: "inline-block",
    margin: "10px",
  },
  marginstyle: {
    margin: "0",
    fontWeight: "bold",
  },
  statestylelabel: {
    marginLeft: "10px",
  },
  inputstyle: {
    width: "288px",
    padding: "10px",
  },
  textareastyle: {
    width: "90%",
    marginLeft: "10px",
  },
  textareamargin: {
    marginLeft: "10px",
    margin: "0",
    fontWeight: "bold",
  },
  statestyle: {
    width: "145px",
    padding: "10px",
  },
  zipcodestyle: {
    width: "110px",
    padding: "9px",
  },
  btnupdate: {
    backgroundColor: "#F8A11C",
    marginRight: "39px",
    marginTop: "5%",
    border: "none",
    padding: "10px",
    width: "120px",
    color: "#fff",
    borderRadius: "5px",
  },
  textaligncenter: {
    textAlign: "center",
  },
  dialogtextaligncenter: {
    textAlign: "center",
    padding: "20px",
  },
  cancelbtn: {
    position: "relative",
    padding: "13px",
    width: "110px",
    marginRight: "10px",
    color: "#fff",
    backgroundColor: "#cdd1d4",
    border: "none",
    borderRadius: "5px",
  },
  deletebtn: {
    position: "relative",
    padding: "13px",
    width: "110px",
    marginRight: "10px",
    color: "#fff",
    backgroundColor: "#fd0e0e",
    border: "none",
    borderRadius: "5px",
  },
  sendbtn: {
    position: "relative",
    padding: "13px",
    width: "110px",
    marginRight: "10px",
    color: "#fff",
    backgroundColor: "#ff9800",
    border: "none",
    borderRadius: "5px",
  },
  textalignbtn: {
    textAlign: "center",
    width: "100%",
  },
  addbtn: {
    width: "200px",
    height: "42px",
    border: "none",
    backgroundColor: "#ff9800",
    color: "#fff",
    borderRadius:"5px",
    marginTop:"22px"
  },
  registertextfield: {
    margin: "0 auto",
    display: "block",
    padding: "10px",
    marginBottom: "19px",
    width: "75%",
  },
  searchbox: {
    border: "none",
    display: "block",
    padding: "11px",
    fontSize: "14px",
    marginTop: "38px",
    borderRadius: "39px",
    width:"200px"
  },
  filterbtnbox: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
  },
  "@media(max-width:570px) and (min-width:450px)": {
    reporttag: {
      width: "150px",
      marginLeft: "50px",
    },
  },
  "@media(max-width:449px)": {
    reporttag: {
      width: "140px",
      marginLeft: "0px",
    },
  },
  "@media(max-width:600px)": {
    notificationtitle: {
      textAlign: "center",
      marginLeft: "0px",
    },
    headertitle: {
      textAlign: "center",
      marginLeft: "0px",
    },
    filterbtnbox:{
      flexDirection:"column",
      alignItems:"center"
    },
  },
};

const useStyles = makeStyles(styles);
// const ITEM_HEIGHT = 48;
export default function SalesRepControls() {
  const url = "https://bathroombackend.instantsolutionslab.site/api/";
  const classes = useStyles();
  const [age, setAge] = React.useState(0);
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const [openbtn, setbtnOpen] = React.useState(false);

  const [firstname, setfirstName] = React.useState("");
  const [lastname, setlastName] = React.useState("");
  const [email, setemail] = React.useState("");
  const [phonenumber, setphoneNumber] = React.useState("");
  const [pass, setPass] = React.useState("");
  const [cpass, setcPass] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [allusers, setallusers] = React.useState();

  const [opentoast, setOpentoast] = React.useState(false);

  const [deleteid, setdeleteid] = React.useState("");

  const handleClickOpen = (param) => {
    // alert(param);
    setdeleteid(param);
    setOpen(true);
  };

  const [userinfo, setUserinfo] = React.useState("");
  React.useEffect(() => {
    setUserinfo(JSON.parse(localStorage.getItem("logininfo")));
    console.log(userinfo);
  }, []);

  const handleClickbtnOpen = () => {
    setbtnOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlebtnClose = () => {
    setbtnOpen(false);
  };

  const handleClick = () => {
    setOpentoast(true);
  };

  const handletoastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpentoast(false);
  };
  React.useEffect(() => {
    getallusers();
  }, []);

  const searchfun = (data) => {
    console.log(data);
    if (data == "" || data.length < 1) {
      getallusers();
    }
    if (data.length > 3) {
      console.log(data);
      getallusersallfilete(data);
    }
  };

  function getallusersallfilete(data) {
    // console.log(JSON.parse(localStorage.getItem("logininfo")));
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    // console.log(usertoken);
    fetch(url + "get-all-users?search=" + data, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        if (data.data.data) {
          setallusers(data.data.data);
        }
        console.log(allusers);
      })
      .catch(console.log);
  }

  function getallusers() {
    // console.log(JSON.parse(localStorage.getItem("logininfo")));
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    // console.log(usertoken);
    fetch(url + "get-all-users", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        if (data.data.data) {
          setallusers(data.data.data);
        }
        console.log(allusers);
      })
      .catch(console.log);
  }

  function Register() {
    if (
      firstname != null &&
      lastname != null &&
      email != null &&
      phonenumber != null &&
      pass != null &&
      cpass != null &&
      firstname != "" &&
      lastname != "" &&
      email != "" &&
      phonenumber != "" &&
      pass != "" &&
      cpass != "" &&
      firstname != undefined &&
      lastname != undefined &&
      email != undefined &&
      phonenumber != undefined &&
      pass != undefined &&
      cpass != undefined
    ) {
      const data = new FormData();
      data.append("first_name", firstname);
      data.append("last_name", lastname);
      data.append("email", email);
      data.append("phone_number", phonenumber);
      data.append("password", pass);
      data.append("password_confirmation", cpass);
      fetch(url + "register", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setMessage("Your account has been successfully created.");
          handlebtnClose();
          handleClick();
          getallusers();
        })
        .catch(console.log);
    } else {
      setMessage("Please fill all fields");
      handleClick();
    }
  }
  function DeleteUser() {
    let da = deleteid;
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    // const data = new FormData();
    fetch(url + "delete-sales/" + da, {
      method: "DELETE",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status) {
          handleClose();
          setMessage(data.message);
          handleClick();
          getallusers();
        }
      })
      .catch(console.log);
  }
  return (
    <div>
      <h3 className={classes.headertitle}>
        Welcome back {userinfo.user?.name}!
      </h3>
      <p className={classes.notificationtitle}>
        <img className={classes.notiicon} src={notiicon} />
        You have 2 appointments this week.
      </p>
      <GridContainer>
        <div className={classes.tableUpgradeWrapper}>
          <div className={classes.filterbtnbox}>
            <input
              type="text"
              placeholder="Search..."
              className={classes.searchbox}
              onChange={(e) => searchfun(e.target.value)}
            />
            <button onClick={handleClickbtnOpen} className={classes.addbtn}>
              Add Sales Rep Controls
            </button>
          </div>

          <div style={{ width: "100%", overflowX: "auto" }}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th className={classes.colortd}>Name</th>
                  <th className={classes.colortd}>Email</th>
                  <th className={classes.colortd}>Phone Number</th>
                  <th className={classes.colortd}>Created Date</th>
                  <th className={classes.colortd}>Delete</th>
                </tr>
              </thead>
              <tbody>
                {allusers?.map((data, key) => {
                  return (
                    <tr key={key}>
                      <td className={classes.center}>
                        {data.first_name} {data.last_name}
                      </td>
                      <td className={classes.center}>{data.email}</td>
                      <td className={classes.center}>{data.phone_number}</td>
                      <td className={classes.center}>{data.created_at}</td>
                      <td className={classes.center}>
                        <img
                          onClick={() => handleClickOpen(data.id)}
                          className={classes.dropdownimg}
                          src={deleteimg}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className={classes.textaligncenter}>
            <Dialog
              className={classes.textaligncenter}
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are You Sure?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  className={classes.textaligncenter}
                  id="alert-dialog-description"
                >
                  Do you really want to delete this record? This process cannot
                  be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.textaligncenter}>
                <div className={classes.textalignbtn}>
                  <button onClick={handleClose} className={classes.cancelbtn}>
                    Cancel
                  </button>
                  <button onClick={DeleteUser} className={classes.deletebtn}>
                    Delete
                  </button>
                </div>
              </DialogActions>
            </Dialog>
          </div>
          <div id="popup-register" className={classes.dialogtextaligncenter}>
            <Dialog
              fullWidth={true}
              maxWidth={"xs"}
              className={classes.textaligncenter}
              open={openbtn}
              onClose={handlebtnClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Create An Account"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  className={classes.textaligncenter}
                  id="alert-dialog-description"
                >
                  Welcome! Register for an account
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.textaligncenter}>
                <div className={classes.textalignbtn}>
                  <input
                    value={firstname}
                    onChange={(e) => setfirstName(e.target.value)}
                    className={classes.registertextfield}
                    type="text"
                    placeholder="Enter First Name"
                  ></input>
                  <input
                    value={lastname}
                    onChange={(e) => setlastName(e.target.value)}
                    className={classes.registertextfield}
                    type="text"
                    placeholder="Enter Last Name"
                  ></input>
                  <input
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    className={classes.registertextfield}
                    type="email"
                    placeholder="Enter Email"
                  ></input>
                  <input
                    value={phonenumber}
                    onChange={(e) => setphoneNumber(e.target.value)}
                    className={classes.registertextfield}
                    type="tel"
                    placeholder="Enter Phone Number"
                  ></input>
                  <input
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                    className={classes.registertextfield}
                    type="password"
                    placeholder="Enter Password"
                  ></input>
                  <input
                    value={cpass}
                    onChange={(e) => setcPass(e.target.value)}
                    className={classes.registertextfield}
                    type="password"
                    placeholder="Enter Confirm Password"
                  ></input>
                  <button
                    onClick={handlebtnClose}
                    className={classes.cancelbtn}
                  >
                    Cancel
                  </button>
                  <button onClick={Register} className={classes.sendbtn}>
                    Add Account
                  </button>
                </div>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        <Snackbar
          open={opentoast}
          autoHideDuration={900}
          onClose={handletoastClose}
          message={message}
        />
      </GridContainer>
    </div>
  );
}
