import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import notiicon from "assets/NotificationIcon.png";
import Generate from "assets/Generate.png";
import Email from "assets/Email.png";
import Group8 from "assets/Group 8.png";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  headertitle: {
    marginBottom: "0",
    marginLeft: "40px",
    color: "#000",
    fontWeight: "400",
  },
  notificationtitle: {
    marginTop: "0",
    fontSize: "12px",
    marginLeft: "42px",
    color: "#000",
    fontWeight: "400",
  },
  gridcontainer: {
    backgroundColor: "#eeeeee",
    height: "498px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardui: {
    textAlign: "center",
    margin: "none",
  },
  cardheader: {
    fontSize: "70px",
    marginBottom: "0",
    fontWeight: "900",
    color: "#F8A11C",
  },
  cardheadermedile: {
    fontSize: "70px",
    marginBottom: "0",
    fontWeight: "900",
    color: "#000",
  },
  carddesc: {
    marginTop: "0",
    marginBottom: "30px",
  },
  reporttag: {
    backgroundColor: "#eee",
    color: "#000",
    marginBottom: "0",
    boxShadow: "unset",
    width: "200px",
    marginLeft: "75px",
    marginTop: "65px",
    textAlign: "center",
    padding: "10px",
  },
  selectui: {
    position: "relative",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "36px",
    height: "30px",
    bottom: "-65px",
    width: "200px",
    textAlign: "center",
    border: "solid 2px #CDD1D4",
    padding: "20px",
  },
  "@media(max-width:570px) and (min-width:450px)": {
    reporttag: {
      width: "150px",
      marginLeft: "50px",
    },
    selectui: {
      width: "160px",
    },
  },
  "@media(max-width:449px)": {
    reporttag: {
      width: "140px",
      marginLeft: "0px",
    },
    selectui: {
      width: "160px",
      marginRight: "0px",
    },
  },

  notiicon: {
    width: "10px",
    marginRight: "5px",
  },
  customers: {
    fontFamily: "Arial, Helvetica, sans-serif",
    borderCollapse: "collapse",
    width: "100%",
  },
  customerstd: {
    border: "1px solid #ddd",
    padding: "8px",
  },
  tableUpgradeWrapper: {
    display: "block",
    width: "100%",
    // overflowX: "auto",
    backgroundColor: "#eee",
    WebkitOverflowScrolling: "touch",
    MsOverflowStyle: "-ms-autohiding-scrollbar",
  },
  table: {
    width: "100%",
    maxWidth: "90% !important",
    marginLeft: "5% !important",
    backgroundColor: "white",
    borderCollapse: "collapse",
    display: "table",
    borderSpacing: "2px",
    borderColor: "grey",
    marginTop: "6%",
    marginBottom: "6%",
    "& thdead tr th": {
      fontSize: "1.063rem",
      padding: "12px 8px",
      verticalAlign: "middle",
      fontWeight: "300",
      borderTopWidth: "0",
      borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
      textAlign: "inherit",
      whiteSpace: "nowrap",
    },
    "& tbody tr td": {
      padding: "12px 8px",
      verticalAlign: "middle",
      borderTop: "1px solid rgba(0, 0, 0, 0.06)",
      whiteSpace: "nowrap",
    },
    "& td, & th": {
      display: "table-cell",
      whiteSpace: "nowrap",
    },
  },
  center: {
    textAlign: "center",
  },
  colortd: {
    backgroundColor: "#CDD1D4",
    textAlign: "center",
    padding: "10px",
  },
  imgwidth: {
    width: "155px",
    marginRight: "5px",
  },
  dropdownimg: {
    width: "46px",
  },
  textright: {
    textAlign: "right",
  },
  divright: {
    // textAlign: "right",
    marginBottom: "50px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  diplayblock: {
    display: "block",
  },
  displayinlineblock: {
    display: "inline-block",
    margin: "10px",
    width: "-webkit-fill-available",
  },
  marginstyle: {
    margin: "0",
    fontWeight: "bold",
    color: "#5A6670",
  },
  statestylelabel: {
    marginLeft: "10px",
    display: "inline-block",
    marginRight: "35px",
  },
  statestylelabel1: {
    display: "inline-block",
  },
  inputstyle: {
    width: "-webkit-fill-available",
    padding: "10px",
    border: "solid 1px #CDD1D4",
    color: "#5A6670",
  },
  textareastyle: {
    width: "90%",
    marginLeft: "10px",
    border: "solid 1px #CDD1D4",
    color: "#5A6670",
  },
  textareamargin: {
    marginLeft: "10px",
    margin: "0",
    fontWeight: "bold",
  },
  statestyle: {
    width: "145px",
    padding: "10px",
    fontSize: "14px",
    border: "solid 1px #CDD1D4",
    color: "#5A6670",
  },
  zipcodestyle: {
    width: "110px",
    padding: "9px",
    border: "solid 1px #CDD1D4",
    color: "#5A6670",
  },
  btnupdate: {
    backgroundColor: "#F8A11C",
    marginRight: "60px",
    marginTop: "5%",
    border: "none",
    padding: "10px",
    width: "120px",
    color: "#fff",
    borderRadius: "5px",
  },
  fieldsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  fieldWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  smallFieldBox: {
    display: "flex",
    flexDirection: "column",
  },
  "@media(max-width:550px)": {
    fieldWrapper: {
      flexDirection: "column",
    },
    inputstyle: {
      width: "-webkit-fill-available",
    },
    displayinlineblock: {
      width: "-webkit-fill-available",
    },
    divright: {
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    table: {
      maxWidth: "100% !important",
      marginLeft: "0% !important",
    },
  },
};

const useStyles = makeStyles(styles);
// const ITEM_HEIGHT = 48;
export default function TableList() {
  const classes = useStyles();
  const [show, setShow] = React.useState(true);
  const [age, setAge] = React.useState(0);
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [userinfo, setUserinfo] = React.useState("");
  React.useEffect(() => {
    setUserinfo(JSON.parse(localStorage.getItem("logininfo")));
    console.log(userinfo);
  }, []);
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  return (
    <div>
      <h3 className={classes.headertitle}>
        Welcome back {userinfo.user?.name}!
      </h3>
      <p className={classes.notificationtitle}>
        <img className={classes.notiicon} src={notiicon} />
        You have 2 appointments this week.
      </p>
      <GridContainer>
        <div className={classes.reporttag}>New Appointments</div>
        <Select
          className={classes.selectui}
          labelId="demo-select-small"
          id="demo-select-small"
          label="Age"
          value={age}
          onChange={handleChange}
        >
          <MenuItem value={0}>Filter by date</MenuItem>
          <MenuItem value={10}>This Week</MenuItem>
          <MenuItem value={20}>30 Days</MenuItem>
          <MenuItem value={30}>This Year</MenuItem>
        </Select>
        <div className={classes.tableUpgradeWrapper}>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th className={classes.colortd}>Name</th>
                  <th className={classes.colortd}>Address</th>
                  <th className={classes.colortd}>Bathroom Model</th>
                  <th className={classes.colortd}>Configured Price</th>
                  <th className={classes.colortd}>Edit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={classes.center}>Sam Roberts</td>
                  <td className={classes.center}>
                    5555 Main St. Dallas TX 78521
                  </td>
                  <td className={classes.center}>Model 1</td>
                  <td className={classes.center}>$10,000</td>
                  <td className={classes.center}>
                    {show == true ? (
                      <img
                        onClick={() => setShow(!show)}
                        className={classes.dropdownimg}
                        src={Group8}
                        style={{ transform: "rotate(180deg)" }}
                      />
                    ) : (
                      <img
                        onClick={() => setShow(!show)}
                        className={classes.dropdownimg}
                        src={Group8}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {show && (
            <div
              style={{ background: "white", marginLeft: "5%", maxWidth: "90%" }}
            >
              <div
                colSpan="5"
                style={{ marginTop: "2rem", paddingTop: "2rem" }}
              >
                <div>
                  <div className={classes.divright}>
                    <img className={classes.imgwidth} src={Generate} />
                    <img className={classes.imgwidth} src={Email} />
                  </div>
                  <div className={classes.fieldsContainer}>
                    <div className={classes.fieldWrapper}>
                      <Grid xs={12}>
                        <div className={classes.displayinlineblock}>
                          <p className={classes.marginstyle}>Name</p>
                          <input
                            className={classes.inputstyle}
                            type="text"
                          ></input>
                        </div>
                      </Grid>
                      <Grid xs={12}>
                        <div className={classes.displayinlineblock}>
                          <p className={classes.marginstyle}>
                            Configured Price
                          </p>
                          <input
                            className={classes.inputstyle}
                            type="text"
                          ></input>
                        </div>
                      </Grid>
                    </div>
                    <div className={classes.fieldWrapper}>
                      <Grid xs={12}>
                        <div className={classes.displayinlineblock}>
                          <p className={classes.marginstyle}>Address</p>
                          <input
                            className={classes.inputstyle}
                            type="text"
                          ></input>
                        </div>
                      </Grid>
                      <Grid xs={12}>
                        <div className={classes.displayinlineblock}>
                          <p className={classes.marginstyle}>Bathroom Model</p>
                          <input
                            className={classes.inputstyle}
                            type="text"
                          ></input>
                        </div>
                      </Grid>
                    </div>

                    <div className={classes.fieldWrapper}>
                      <Grid xs={12}>
                        <div className={classes.smallFieldBox}>
                          <div className={classes.statestylelabel}>
                            <p className={classes.marginstyle}>State</p>
                            <select
                              className={classes.statestyle}
                              name="state"
                              id="state"
                            >
                              <option value="volvo">TX</option>
                              <option value="saab">Saab</option>
                              <option value="mercedes">Mercedes</option>
                              <option value="audi">Audi</option>
                            </select>
                          </div>
                          <div
                            className={classes.statestylelabel}
                            style={{ marginTop: "15px" }}
                          >
                            <p className={classes.marginstyle}>Zip Code</p>
                            <input
                              className={classes.zipcodestyle}
                              type="text"
                            ></input>
                          </div>
                        </div>
                      </Grid>
                      <Grid xs={12}>
                        <p className={classes.textareamargin}>Notes</p>
                        <textarea
                          rows="8"
                          className={classes.textareastyle}
                        ></textarea>
                      </Grid>
                    </div>
                  </div>
                  <div className={classes.divright}>
                    <button className={classes.btnupdate}>UPDATE</button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </GridContainer>
    </div>
    // <GridContainer>
    //   <GridItem xs={12} sm={12} md={12}>
    //     <Card>
    //       <CardHeader color="primary">
    //         <h4 className={classes.cardTitleWhite}>Simple Table</h4>
    //         <p className={classes.cardCategoryWhite}>
    //           Here is a subtitle for this table
    //         </p>
    //       </CardHeader>
    //       <CardBody>
    //         <Table
    //           tableHeaderColor="primary"
    //           tableHead={["Name", "Country", "City", "Salary"]}
    //           tableData={[
    //             ["Dakota Rice", "Niger", "Oud-Turnhout", "$36,738"],
    //             ["Minerva Hooper", "Curaçao", "Sinaai-Waas", "$23,789"],
    //             ["Sage Rodriguez", "Netherlands", "Baileux", "$56,142"],
    //             ["Philip Chaney", "Korea, South", "Overland Park", "$38,735"],
    //             ["Doris Greene", "Malawi", "Feldkirchen in Kärnten", "$63,542"],
    //             ["Mason Porter", "Chile", "Gloucester", "$78,615"],
    //           ]}
    //         />
    //       </CardBody>
    //     </Card>
    //   </GridItem>
    //   <GridItem xs={12} sm={12} md={12}>
    //     <Card plain>
    //       <CardHeader plain color="primary">
    //         <h4 className={classes.cardTitleWhite}>
    //           Table on Plain Background
    //         </h4>
    //         <p className={classes.cardCategoryWhite}>
    //           Here is a subtitle for this table
    //         </p>
    //       </CardHeader>
    //       <CardBody>
    //         <Table
    //           tableHeaderColor="primary"
    //           tableHead={["ID", "Name", "Country", "City", "Salary"]}
    //           tableData={[
    //             ["1", "Dakota Rice", "$36,738", "Niger", "Oud-Turnhout"],
    //             ["2", "Minerva Hooper", "$23,789", "Curaçao", "Sinaai-Waas"],
    //             ["3", "Sage Rodriguez", "$56,142", "Netherlands", "Baileux"],
    //             [
    //               "4",
    //               "Philip Chaney",
    //               "$38,735",
    //               "Korea, South",
    //               "Overland Park",
    //             ],
    //             [
    //               "5",
    //               "Doris Greene",
    //               "$63,542",
    //               "Malawi",
    //               "Feldkirchen in Kärnten",
    //             ],
    //             ["6", "Mason Porter", "$78,615", "Chile", "Gloucester"],
    //           ]}
    //         />
    //       </CardBody>
    //     </Card>
    //   </GridItem>
    // </GridContainer>
  );
}
